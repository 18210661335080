// import { axios } from '../utils/request'
import { axios } from '../utils/request_new'

/* 企业中心-获取应用包 */
export function getAppPackages(params) {
  return axios({
    url: '/ware-engine/app-packages',
    method: 'get',
    params,
  })
}

/* 企业中心-创建应用包 */
export function createAppPackages(data) {
  return axios({
    url: '/ware-engine/app-packages',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 企业中心-更新应用包 */
export function updateAppPackages(data,id) {
  return axios({
    url: `/ware-engine/app-packages/${id}`,
    method: 'put',
    data,
  })
}

/* 企业中心-删除应用包 */
export function deleteAppPackages(id) {
  return axios({
    url: `/ware-engine/app-packages/${id}`,
    method: 'DELETE',
  })
}

export const wareengineApi = {
    getAppPackages,
    createAppPackages,
    updateAppPackages,
    deleteAppPackages,
}
