import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import aes from './utils/encrypt'
import '@/assets/font/iconfont.css'
import SlideVerify from 'vue-monoplasty-slide-verify'
import moment from 'moment'
import VueClipboard from 'vue-clipboard2'
import '@/assets/icon/iconfont.css'

// import Vant from 'vant'
// import 'vant/lib/index.css'

import { loginApi } from './api/loginApi'
import { userApi } from './api/userApi'
import { companyApi } from './api/companyApi'
import { authApi } from './api/authApi'
import { platformApi } from './api/platformApi'
import { warestoreApi } from './api/warestoreApi'
import { wareengineApi } from './api/wareengineApi'

import 'element-ui/lib/theme-chalk/index.css'

import component from '@/components/VueText'
import unifyAvatar from '@/components/unifyAvatar'
Vue.use(component)
Vue.use(unifyAvatar)

Vue.use(ElementUI)
// Vue.use(Vant)
Vue.use(SlideVerify)
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.prototype.$aes = aes
Vue.prototype.$moment = moment
Vue.prototype.$api = {
  ...loginApi,
  ...userApi,
  ...companyApi,
  ...authApi,
  ...platformApi,
  ...warestoreApi,
  ...wareengineApi,
}

export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
