import { axios } from '../utils/request_new'

/* 获取用户信息 */
export function getUserinfo() {
  return axios({
    url: '/platform/userinfo',
    method: 'GET',
  })
}

/* 获取地址列表 */
export function getAddressesList() {
  return axios({
    url: '/platform/addresses',
    method: 'GET',
  })
}

/* 用户创建租户（企业或组织） */
export function createCompany(data) {
  return axios({
    url: '/platform/organizations',
    method: 'POST',
    data,
  })
}

/* 获取当前组织邀请码 */
export function getOrgInvitationCode() {
  return axios({
    url: '/platform/org-invitation-code/current',
    method: 'GET',
  })
}

/* 通过邀请码加入组织 */
export function orgInvitationCodeAccept(data) {
  return axios({
    url: '/platform/org-invitation-code/accept',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 获得邀请码详细信息 */
export function getInviteCodeInfo(code) {
  return axios({
    url: `/platform/invitation-code/${code}`,
    method: 'GET',
  })
}

/* 启用邀请码启用状态 */
export function updateInviteCodeStatus() {
  return axios({
    url: `/platform/org-invitation-code/enable`,
    method: 'post',
  })
}

/* 关闭邀请码启用状态 */
export function closeInviteCodeStatus() {
  return axios({
    url: `/platform/org-invitation-code/disable`,
    method: 'post',
  })
}

/* 获得加入组织请求分页*/
export function getjoinOrgRequestsList(params) {
  return axios({
    url: `/platform/join-organization-requests`,
    method: 'GET',
    params,
  })
}

// 同意加入组织申请
export function approveJoinOrgRequest(id, data) {
  return axios({
    url: `/platform/join-organization-requests/${id}/approve`,
    method: 'POST',
    data,
  })
}

// 拒绝加入组织申请
export function rejectJoinOrgRequest(id) {
  return axios({
    url: `/platform/join-organization-requests/${id}/reject`,
    method: 'POST',
  })
}

/* 用户信息更新 */
export function userInfoUpdate(data) {
  return axios({
    url: '/platform/my-profile',
    method: 'PUT',
    data,
  })
}

//
export function getUserInfoUpdate(data) {
  return axios({
    url: '/platform/my-profile',
    method: 'GET',
  })
}

/* 重置手机号 */
export function phoneNumberReset(data) {
  return axios({
    url: '/platform/account/phone-number-reset',
    method: 'PUT',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
/* 发送注销用户验证码 */
export function smsDeactivateUser(data) {
  return axios({
    url: '/platform/sms/deactivate',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 获取所有部门 /departments*/
export function getDepartments(data) {
  return axios({
    url: '/platform/departments',
    method: 'GET',
  })
}

/* 创建部门 */
export function addDepartments(data) {
  return axios({
    url: '/platform/departments',
    method: 'POST',
    data,
  })
}

/* 删除部门 */
export function deleteDepartments(id) {
  return axios({
    url: `/platform/departments/${id}`,
    method: 'DELETE',
  })
}

/* 更新部门 */
export function updateDepartments(id, data) {
  return axios({
    url: `/platform/departments/${id}`,
    method: 'PUT',
    data,
  })
}

/* 分配部门 */
export function assignmentDepartments(id, data) {
  return axios({
    url: `/platform/department/${id}/assignment`,
    method: 'POST',
    data,
  })
}

/* 获得全部部门管理员 */
export function getDeptLeaderList(id) {
  return axios({
    url: `/platform/departments/${id}/admin-members`,
    method: 'GET',
  })
}

/* 移除部门职位 */
export function deletePositions(data) {
  return axios({
    url: `/platform/positions`,
    method: 'DELETE',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
/* 部门任职 */
export function setDepPositions(departmentId, data) {
  return axios({
    url: `/platform/departments/${departmentId}/positions`,
    method: 'POST',
    data,
  })
}

/* 获取部门下所有成员 */
export function getDepAllMembers(departmentId) {
  return axios({
    url: `/platform/departments/${departmentId}/members`,
    method: 'GET',
  })
}

/* 获取当前组织成员分页 */
export function getMemberList(data) {
  return axios({
    url: `/platform/members`,
    method: 'GET',
    params: data,
  })
}

/* 获得当前用户加入的组织列表 */
export function getOrganizationsList() {
  return axios({
    url: '/platform/joined-organizations',
    method: 'GET',
  })
}

// 获得权限组
export function getPermissionGroups() {
  return axios({
    url: '/platform/permission-groups',
    method: 'GET',
  })
}

// 获取租户下的角色列表
export function getRoles() {
  return axios({
    url: '/platform/roles',
    method: 'GET',
  })
}

// 创建角色
export function createRoles(data) {
  return axios({
    url: '/platform/roles',
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// 更新角色名字
export function updateRoles(data, roleId) {
  return axios({
    url: `/platform/roles/${roleId}/rename`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export function deleteRoles(roleId) {
  return axios({
    url: `/platform/roles/${roleId}`,
    method: 'DELETE',
  })
}

// 为角色添加权限信息
export function addRolesPermission(roleId, permissionId) {
  return axios({
    url: `/platform/roles/${roleId}/permissions/${permissionId}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

// 根据code,角色添加权限
export function createPermissionsCode(data, roleId) {
  return axios({
    url: `/platform/roles/${roleId}/permissions/code`,
    method: 'POST',
    data,
  })
}

// 根据code,角色移除权限
export function deletePermissionsCode(roleId, code) {
  return axios({
    url: `/platform/roles/${roleId}/permissions/code/${code}`,
    method: 'DELETE',
  })
}

// 批量根据code,角色添加权限
export function createPermissionsCodeBatch(data, roleId) {
  return axios({
    url: `/platform/roles/${roleId}/permissions/code/batch`,
    method: 'POST',
    data,
  })
}

// 批量根据code,角色移除权限
export function deletePermissionsCodeBatch(roleId, data) {
  return axios({
    url: `/platform/roles/${roleId}/permissions/code/batch?codes=${data}`,
    method: 'DELETE',
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // },
  })
}

// 获取角色的所有权限
export function getRolesPermissions(roleId) {
  return axios({
    url: `/platform/roles/${roleId}/permissions`,
    method: 'GET',
  })
}

// 成员添加角色
export function addMembersRoles(userId, roleId) {
  return axios({
    url: `/platform/members/${userId}/roles/${roleId}`,
    method: 'PUT',
  })
}

// 成员移除角色
export function deleteMembersRoles(userId, roleId) {
  return axios({
    url: `/platform/members/${userId}/roles/${roleId}`,
    method: 'DELETE',
  })
}

// 获得拥有该角色的所有成员
export function getRolesMemberList(roleId) {
  return axios({
    url: `/platform/roles/${roleId}/members`,
    method: 'GET',
  })
}

// 工商信息
export function getBusinessInformation() {
  return axios({
    url: `/platform/current-organization/business-information`,
    method: 'GET',
  })
}
// 基本信息
export function getBasicInformation() {
  return axios({
    url: '/platform/current-organization/simple-information',
    method: 'GET',
  })
}

// 更新组织信息（基本信息+工商信息）
export function updateCompanyInfo(data) {
  return axios({
    url: `/platform/current-organization/information`,
    method: 'PUT',
    data,
  })
}

// 获取当前租户详情
export function getCurrentInformation() {
  return axios({
    url: '/platform/current-organization/information',
    method: 'GET',
  })
}

// 通讯录添加成员 /add-member
export function addMembers(data, roleId) {
  return axios({
    url: `/platform/add-member`,
    method: 'POST',
    data,
  })
}

// 通讯录修改编辑成员
export function updateMembersInfo(id, data) {
  return axios({
    url: `/platform/members/${id}`,
    method: 'PUT',
    data,
  })
}

// 通讯录移除成员
export function deleteMembersInfo(id) {
  return axios({
    url: `/platform/members/${id}`,
    method: 'DELETE',
  })
}

// 获取微信绑定二维码
export function getBindQrcode() {
  return axios({
    url: '/auth/wx/mp/qrcode/bind',
    method: 'POST',
  })
}

/* 员工离职 */
export function dimission(data, member_id) {
  return axios({
    url: `/platform/members/${member_id}/resign`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

/* 离职员工列表 */
export function getDimissionUserList() {
  return axios({
    url: '/platform/resignation-information',
    method: 'GET',
  })
}

/* 获取指定成员信息 */
export function getMemberInfo(id) {
  return axios({
    url: `/platform/members/${id}`,
    method: 'GET',
  })
}

/* 获得我加入组织请求分页 */
export function getMyJoinOrganization(data) {
  return axios({
    url: `/platform/my-join-organization-requests`,
    method: 'GET',
    data,
  })
}

/* 获取企业登陆日志 */
export function getCompanyLogList(params) {
  return axios({
    url: `/auth/auth-logs`,
    method: 'get',
    params,
  })
}

/* 查询角色关联应用 */
export function appCenterMemberSetInfo(roleId) {
  return axios({
    url: `/bigtable/app-member-view/role/${roleId}`,
    method: 'get',
  })
}

/* 获取应用包 */
export function getAppPackage() {
  return axios({
    url: `/bigtable/app-packages`,
    method: 'get',
    params: {
      page: 1,
      size: 999,
    },
  })
}

/* 获取应用包下应用列表 */
export function getAppListByAppPackage(appIds) {
  // return axios({
  //   url: `/bigtable/v1/apps`,
  //   method: 'get',
  //   params: {
  //     page: 1,
  //     size: 999,
  //     appPackageId,
  //   },
  // })
  return axios({
    // url: `/bigtable/applications`,
    url: `/bigtable/apps`,
    method: 'post',
    data: {
      ids: appIds
    },
    // data: appIds,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  })
}

/* 获取应用包下应用的成员权限*/
export function getAppMemberRole(data) {
  return axios({
    url: `/bigtable/members`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  })
}

/* 更新应用成员权限 */
export function updateMemberRole(data) {
  return axios({
    url: `/bigtable/members`,
    method: 'put',
    data,
  })
}

/* 删除应用成员权限 */
export function deleteMemberRole(roleId) {
  return axios({
    url: `/bigtable/members/${roleId}`,
    method: 'delete',
  })
}

/* 查询部门负责人信息 */
export function getDepartmentPrincipal(department_id) {
  return axios({
    url: `/platform/departments/${department_id}/admin-members`,
    method: 'get',
  })
}

export const platformApi = {
  getUserinfo,
  getAddressesList,
  getOrgInvitationCode,
  orgInvitationCodeAccept,
  createCompany,
  getInviteCodeInfo,
  updateInviteCodeStatus,
  closeInviteCodeStatus,
  getjoinOrgRequestsList,
  approveJoinOrgRequest,
  rejectJoinOrgRequest,
  userInfoUpdate,
  getUserInfoUpdate,
  phoneNumberReset,
  smsDeactivateUser,
  getDepartments,
  addDepartments,
  deleteDepartments,
  updateDepartments,
  assignmentDepartments,
  getDeptLeaderList,
  deletePositions,
  setDepPositions,
  getDepAllMembers,
  getMemberList,
  getOrganizationsList,
  getPermissionGroups,
  getRoles,
  createRoles,
  addRolesPermission,
  createPermissionsCode,
  deletePermissionsCode,
  createPermissionsCodeBatch,
  deletePermissionsCodeBatch,
  getRolesPermissions,
  addMembersRoles,
  deleteMembersRoles,
  getRolesMemberList,
  updateRoles,
  deleteRoles,
  getBusinessInformation,
  getBasicInformation,
  updateCompanyInfo,
  getCurrentInformation,
  addMembers,
  updateMembersInfo,
  deleteMembersInfo,
  getBindQrcode,
  dimission,
  getDimissionUserList,
  getMemberInfo,
  getMyJoinOrganization,
  getCompanyLogList,
  appCenterMemberSetInfo,
  getAppPackage,
  getAppListByAppPackage,
  getAppMemberRole,
  updateMemberRole,
  deleteMemberRole,
  getDepartmentPrincipal,
}
